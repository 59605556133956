import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';
import confetti from "canvas-confetti";
import GameController from '../Controller/GameController';
import images from '../Model/images';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Checkbox, CheckboxGroup } from "@nextui-org/react";
import { getAuth,signOut } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faGroupArrowsRotate, faCirclePlay, } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Question from "../Components/Question";
import { motion } from "framer-motion";
import '../styles/main.css'; // Assurez-vous d'avoir ce fichier de style ou ajustez le chemin





// La hauteur de chaque image dans le spinner et la durée de l'animation
const imageHeight = 100; // ajustez selon la taille de vos images
const spinDuration = 2; // durée de l'animation en secondes

const SlotMachine = () => {
  const [slots, setSlots] = useState([[], [], []]); // Chaque slot est un tableau d'images
  const [isSpinning, setIsSpinning] = useState(false);
  const [message, setMessage] = useState('');
  const [stoppedPositions, setStoppedPositions] = useState([0, 0, 0]); // Les positions d'arrêt pour chaque spinner
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [chancesLeft, setChancesLeft] = useState(3);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const auth = getAuth();

  const handleRetry = () => {
    setIsResetOpen(false);  // Fermer le modal
    setChancesLeft(3);      // Réinitialiser le nombre de chances à 3
  };

  useEffect(() => {
    if (
      chancesLeft === 0 && !isPopupOpen && !isSpinning) {
      // Ouvrir le deuxième modal lorsque les conditions sont remplies
      setIsResetOpen(true);
    }
  }, [chancesLeft, isResetOpen, isSpinning]);

  const handleQuit = async () => {
    try {
      // Déconnectez l'utilisateur anonyme
      await signOut(auth);
    } catch (error) {
      console.error("Erreur lors de la déconnexion : ", error);
    }
  };
  
  const handleStoppedPosition = (index, position) => {
    // Mettre à jour les positions d'arrêt pour chaque spinner
    setStoppedPositions(prevPositions => {
      const newPositions = [...prevPositions];
      newPositions[index] = position;
      return newPositions;
    });
  };

  
  // Générer les positions d'arrêt pour chaque spinner
  const generateStoppedPositions = () => {
    return Array.from({ length: slots.length }, () => {
      // Générer une position d'arrêt aléatoire pour chaque spinner
      const position = -imageHeight * Math.floor(Math.random() * images.length);
      // console.log(`Position d'arrêt générée: ${position}`);
      return position;
    });
  };



  const handleSpin = async () => {
    if (isSpinning || chancesLeft === 0) 
    
     return ;

    setIsSpinning(true);
    
    // Décrémenter le nombre de chances restantes
    setChancesLeft((prevChances) => prevChances - 1);

    
    // Générer les positions d'arrêt avant de démarrer le spin
   
    const newStoppedPositions = generateStoppedPositions();
    setStoppedPositions(newStoppedPositions);

    const results = await GameController.spin(images);
    setSlots(results);

    

   
    
    
    // Arrêter le défilement après un certain temps
    setTimeout(() => {
      setIsSpinning(false);
       // Log des positions d'arrêt
      //  console.log("Positions d'arrêt finales:", newStoppedPositions);
      
      // Déterminer si le joueur a gagné
      // Ici, on suppose que la position de la troisième image est à -200px pour chaque spinner
      const winningPosition = -2 * imageHeight; // La position de la troisième image
      const win = GameController.checkWin(newStoppedPositions, winningPosition);
      // console.log(win ? "Gagné" : "Perdu");
      // console.log(win);
      
      //setMessage(win ? 'Gagner!' : 'Essayez encore!');
      if (win) {
        setIsPopupOpen(true);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      }
      
    }, spinDuration * 1000 + 500); // Assurez-vous que ce délai est suffisant pour voir le défilement // Ce délai doit être supérieur à la durée de spinDuration pour permettre le défilement
    // console.log(stoppedPositions);
  };

  return (
    <div style={{ textAlign: "center", fontFamily: "sans-serif" }}>

    <CheckboxGroup
    orientation="horizontal"
    value={chancesLeft === 3 ? [] : chancesLeft === 2 ? ["1"] : chancesLeft === 1 ? ["1", "2"] : chancesLeft === 0 ? ["1", "2", "3"] : []}

    size="sm"
    style={{ alignItems: 'center' }}

  >
    <Checkbox value="1" color="primary" />
    <Checkbox value="2" color="warning" />
    <Checkbox value="3" color="danger" />
  </CheckboxGroup>

      

    <div className="slot-machine">
<div className="spinners">
  {slots.map((slot, index) => (
    <Spinner
      key={index} 
      images={images}
      spinning={isSpinning}
      index={index}
      stoppedPosition={stoppedPositions[index]} // Passer la position d'arrêt à chaque Spinner
    />
  ))}
</div>

      {/* <Button onClick={handleSpin} disabled={isSpinning}>
        
      <FontAwesomeIcon icon={isSpinning ? faGroupArrowsRotate : faCirclePlay}
          beat
          spin={isSpinning}
          spinReverse={isSpinning} className="mr-2" size="2xl"
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        />
      </Button>
      {message && <div className="message">{message}</div>} */}
            {/* <motion.button
        onClick={handleSpin}
        disabled={isSpinning}
        // style={{
        //   //backgroundColor: "#ff3300",
        //   color: "#fff",
        //   border: "none",
        //   padding: "20px",
        //   fontSize: "15px",
        //   borderRadius: "50%",
        //   cursor: "pointer",
        //   boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.3)",
        //   position: "absolute",
        //   top: "80%",
        //   left: "50.3%",
        //   transform: "translate(-50%, -50%)",
        //   zIndex: 3,
        // }}
      > */}
        <Button
          onClick={handleSpin}
          disabled={isSpinning}
          style={{
            backgroundColor: "orange",
            color: "white",
          }}
        >
          Jouer
        </Button>
      {/* </motion.button> */}

    </div>

          <Modal
        backdrop="opaque"
        isOpen={isPopupOpen}
        onOpenChange={() => setIsPopupOpen(false)} // Fermer le popup en changeant l'état
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Félicitation!</ModalHeader>
              <ModalBody>
                <p>Vous êtes éligible pour remporter le lot</p>
                
              </ModalBody>
              <ModalFooter>

                <Question />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        backdrop="opaque"
        isOpen={isResetOpen}
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
                <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Réessayer?</ModalHeader>
              <ModalBody>
                <p>Tentez votre chance de nouveau </p>
                
              </ModalBody>
              <ModalFooter>


          
              <Button
            className="bg-[#F9FAFA] shadow-lg shadow-green-500/20"
            onPress={handleRetry}
          >
            Réessayer
          </Button>
          
          <Link to="/win" >
          <Button color="foreground" variant="light" onPress={handleQuit}>
            Quitter
          </Button>
          </Link>
          
          </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      </div>
  );
};

export default SlotMachine;