import React, { useState } from "react";
import { Card, CardHeader, CardBody, Modal, ModalContent, ModalHeader, ModalBody, Button, Image,  } from "@nextui-org/react";
import SlotMachine from "../Component/SlotMachine";




export default function Slot() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{
      justifyContent: "center", alignItems: "center", display: "flex"

    }}>
      <Card className="py-4" style={{
        width: '800px',
        height: '600px',

        backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/jackpot-8cad0.appspot.com/o/background%2FJEU%20back.png?alt=media&token=6e17db93-2de9-42c4-b5ae-5d842f47b98b")', // Définissez l'image de fond ici
        backgroundSize: 'cover', // Ajustez la taille de l'image de fond selon vos besoins
        backgroundPosition: 'center', // Centrez l'image de fond horizontalement et verticalement
        
        
        

      }}>
        <style>
          {`
        .centered-content {
            display: flex;
            justify-content: center; /* Centre horizontalement */
            align-items: center; /* Centre verticalement */
            flex-direction: column; /* Pour centrer le contenu verticalement */
            height: 100%; /* Remplir la hauteur de la carte */
          }
          
          .custom-card {
            width: 450px;
            /* Ajoutez d'autres styles personnalisés ici */
          }
          
        `}

        </style>
        <div 
        // style={{
        //   backdropFilter: "blur(50px)",
        //   backgroundColor: "rgba(255, 255, 255, 0.2)",
        //   }}
        >
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start centered-content"
        
        >
          <div className="flex items-center mb-1">
          <Image
            alt="nextui logo"
            height={80}
            radius="sm"
            src="https://firebasestorage.googleapis.com/v0/b/jackpot-8cad0.appspot.com/o/images%2FLogo%20Moov%20africa%202.png?alt=media&token=24f68ef3-009f-422f-bdb3-5655903c54bb"
            width={80}
            top={100}
          />

            
          </div>


        </CardHeader>
        <CardBody className="overflow-visible py-2 centered-content" style={{ marginTop: '-80px' }}>

          <SlotMachine />

        </CardBody>
        </div>
      </Card>
      {/* Modal avec une image */}
      <Modal backdrop="opaque"
        isOpen={isModalOpen}
        onClose={closeModal}
        classNames={{
          //body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          //base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          //header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-black/5 active:bg-black/10",
        }}
      >
        <ModalContent>
          <ModalHeader>
            Comment Jouer
          </ModalHeader>

          <ModalBody>
            {/* Ajoutez ici votre image */}
            <Image src="https://firebasestorage.googleapis.com/v0/b/jackpot-8cad0.appspot.com/o/images%2Fcomment.png?alt=media&token=b8cd6a6a-3f3d-45a7-9300-d8176b5ea16a" alt="Tuto" width={400} height={300} />
          </ModalBody>


          <p
            //ajoute un style pour centrer
            className="text-center text-sm text-gray-500 dark:text-gray-400 py-2"
          >
            Cliquez sur le bouton pour lancer la machine
          </p>
          <p
            //ajoute un style pour centrer
            className="text-center text-sm text-gray-500 dark:text-gray-400 py-2"
          >
            Vous Avez 3 chances de gagner
          </p>
          <p
            //ajoute un style pour centrer
            className="text-center text-sm text-gray-500 dark:text-gray-400 py-2"
          >
            Vous gagner lorsque vous tomber sur le logo 4G+
          </p>
          <Button className="bg-[#1a4edb] shadow-lg shadow-indigo-500/20" onClick={closeModal} style={{ backgroundColor: "#1a4edb", color: "white" }} >
            Fermer
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}