import slot1 from '../images/slot/1.png'
import slot2 from '../images/slot/2.png'

import slot4 from '../images/slot/4.png'



class GameController {
    // Remplacez ceci par vos propres images ou mécanisme de génération
    static images = [
      slot1,
      slot2,
      
      slot4,
      // slot5,
      // slot6,
      // slot7,
      // slot8,
      // slot9

      
      // Ajoutez autant d'images que vous avez dans votre jeu
    ];
  
    // Méthode pour démarrer le spin et retourner les résultats
    static spin() {
      return new Promise(resolve => {
        setTimeout(() => {
          const results = this.randomImages();
          resolve(results);
        }, 1000); // Durée du spin, ajustez selon le besoin
      });
    }
  
    // Méthode pour générer aléatoirement des images pour les rouleaux
    static randomImages() {
      return Array.from({ length: 3 }, () => this.images[Math.floor(Math.random() * this.images.length)]);
    }
  
    // Méthode pour vérifier si l'utilisateur a gagné
    static checkWin(stoppedPositions, winningPosition) {
      return stoppedPositions.every(position => position === winningPosition);
    }
  }
  
  export default GameController;
  